



























































































































































import Vue from 'vue';
import moment from 'moment';
import * as Api from '@/api/order';
export default Vue.extend({
  data() {
    return {
      dataSource: [] as any[],
      infoData: [] as any[],
      dealVisible: false,
    };
  },
  mounted() {
    this.searchDetail();
  },
  methods: {
    moment,
    /**
     * @description: 详情查询
     * @param {*}
     * @return {*}
     */
    searchDetail() {
      const invoiceOrderNumber = this.$route.query.id;
      Api.invoiceDetail(invoiceOrderNumber).then((res) => {
        if (res.status === 200) {
          const result = [res.result];
          console.log('arr', result);
          this.dataSource = result;
          this.infoData = result;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 去处理按钮
     * @param {*}
     * @return {*}
     */
    deal() {
      const invoiceOrderNumList = [this.$route.query.id];
      const state = 1;
      Api.auditDetail(invoiceOrderNumList, state).then((res) => {
        if (res.status === 200) {
          this.$message.info('处理成功');
          this.$router.push({
            path: '/invoice',
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
});
